import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'
// import Markdown from 'react-markdown'

import Layout from '../../components/layout'

import '../../components/antd/less/antMotionStyle.less'
import SEO from '../../components/seo'
import pic1 from '../../images/hardware2.jpg'
import pic2 from '../../images/hardware3.jpg'
import pic3 from '../../images/bug5.jpg'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi
    return (
      <Layout products={products} productGroups={productGroups} curIndex={2}>
        <SEO title='智能硬件产品' />
        <div className='core-tech-wrap hardware-wrap'>
          <div className='main-img'></div>

          <div className='main-con'>
            <h2>简介</h2>
            <p className='description-text'>
              易见纹语科技基于领先的Al算法、区块链及硬件加速技术，打造了一整套&Prime;云+端&Prime;的智能硬件产品系列。该产品系列可以搭载不同
              的AI智能算法，搭载不同的光学传感器和非光学传感器，因此能灵活服务于农产品自动溯源赋码、自动质检及分拣等多个场景，让数字农
              业的加工生产、渠道流通等环节实现数字化、自动化、无人化。
            </p>
          </div>
        </div>
        <div
          className='core-tech-wrap solution-wrap'
          style={{ backgroundColor: '#f5f5f5' }}
        >
          <div className='main-con'>
            <h2>产品介绍</h2>
            <div className='hardware-card shadow'>
              <div className='hardware-card-title'>数耘一体机2.0</div>
              <div className='hardware-card-sub-title'>
                数耘一体机集合了智能计算、区块链上链、光学智能等能力于一体的智能硬件产品。配合易见纹语科技的区块+AI的溯源技术及溯源产品管理
                软件，为中小型企业提供&Prime;开箱即用&Prime;的产品溯源赋码能力，支持“一物一码”的自动溯源赋码及&Prime;多级赋码打包&Prime;的能力。
                通过数耘一体机,企业可以高效快捷地实现为每一批次产品溯源赋码。
              </div>
              <div className='float-box hardware-card-bottom'>
                <div className='float-box-col45'>
                  <img src={pic1} />
                </div>
                <div className='float-box-col50' style={{ float: 'right' }}>
                  <div className='hardware-float-right-title'>产品要点</div>
                  <div>
                    <ul className='no-style-ul hardware-float-right-content'>
                      <li>AI纹路特征提取+区块链上链</li>
                      <li>基于边缘计算的AI图像分析</li>
                      <li>自带二维码读取器，“一物一码”二维码自动绑定</li>
                      <li>支持光学调整</li>
                      <li>支持摄像头参数自动调优</li>
                      <li>摄像头距离可调，适应各种物体尺寸</li>
                      <li>工控机+触摸屏操控</li>
                      <li>支持软件远程自动升级</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className='hardware-card shadow'>
              <div className='hardware-card-title'>数耘智门1.0</div>
              <div className='hardware-card-sub-title'>
                数耘智门是配合加工生产过程使用的流水线装置的智能设备，适合中大型加工生产企业。数耘智门通过工控机控制传送带装置、分拣装置，赋
                予了生产线智能能力，成为传统产线上的&Prime;智能大脑&Prime;。通过&Prime;云+端&Prime;的设计，数耘智门可以实现软件定义智能功能，实现不同的智能自动
                化、无人化加工场景，包括流水线上的自动化溯源赋码、在线成分分析、智能质量分拣等。
              </div>
              <div className='float-box hardware-card-bottom'>
                <div className='float-box-col45'>
                  <img src={pic2} />
                </div>
                <div className='float-box-col50' style={{ float: 'right' }}>
                  <div className='hardware-float-right-title'>产品要点</div>
                  <div>
                    <ul className='no-style-ul hardware-float-right-content'>
                      <li>
                        支持各种传感设备（可见光/结构光/近红外等摄像头，气味传感器等）
                      </li>
                      <li>高速无人操作，支持各种传送带架设条件</li>
                      <li> &Prime;一物一码&Prime;二维码自动绑定</li>
                      <li>摄像头参数自动调优</li>
                      <li>摄像距离可调，适应各种物体尺寸</li>
                      <li>工控机+触摸屏操控</li>
                      <li>支持软件远程自动升级</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={pic3} />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
